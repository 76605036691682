import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchProductCategories(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('admin/product/categories')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addCategory(ctx, { body }) {
      return new Promise((resolve, reject) => {
        axios
          .post('admin/product/category/store', body)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateCategory(ctx, { id, body }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`admin/product/category/${id}/update`, body)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteCategory(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`admin/product/category/${id}/delete `)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
