<template>
  <div>
    <!-- Table Container Card -->
    <category-handler-sidebar
      v-model="isProductCategoryHandlerSidebarActive"
      :category="category"
      :clear-category-data="clearCategoryData"
      @add-category="addCategory"
      @update-category="updateCategory"
    />
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <div class="">
              <h3>Total de categorias</h3> <small> (Hay {{ totalProductCategories }} blogs)</small>
            </div>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end  mb-1 mb-md-0">
              <b-button
                variant="primary"
                @click="isProductCategoryHandlerSidebarActive = true"
              >
                Crear
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refProductCategoriesListTable"
        class="position-relative"
        :items="fetchProductCategories"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        empty-text="No se encontraron registros coincidentes"
      >

        <!-- Column: Client -->
        <template #cell(photo)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-img
                rounded
                thumbnail
                fluid
                :src="data.item.photo"
                alt="Blog dramox"
              />
            </template>
          </b-media>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <div class="text-nowrap">
            <feather-icon
              :id="`invoice-row-${data.item.id}-send-icon`"
              icon="EditIcon"
              class="cursor-pointer"
              size="16"
              @click="editCategory(data.item)"
            />
            <b-tooltip
              title="Editar"
              class="cursor-pointer"
              :target="`invoice-row-${data.item.id}-send-icon`"
            />

            <feather-icon
              :id="`invoice-row-${data.item.id}-preview-icon`"
              icon="TrashIcon"
              size="16"
              class="mx-1 cursor-pointer"
              @click="deleteCategory(data.item)"
            />
            <b-tooltip
              title="Eliminar"
              :target="`invoice-row-${data.item.id}-preview-icon`"
            />
          </div>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="12"
            class="d-flex align-items-center justify-content-end justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalProductCategories"
              per-page="10"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>

  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BTable,
  BMedia,
  BPagination,
  BTooltip,
  BImg,
} from 'bootstrap-vue'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import productCategoryHandler from '@/views/dramox/product/category/productCategoryHandler'
import Ripple from 'vue-ripple-directive'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import CategoryHandlerSidebar from '@/views/dramox/product/category/sidebar/categoryHandlerSidebar'
import categoryStoreModule from '@/views/dramox/product/category/categoryStoreModule'

export default {
  components: {
    CategoryHandlerSidebar,
    BCard,
    BRow,
    BCol,
    BTable,
    BMedia,
    BPagination,
    BTooltip,
    BButton,
    BImg,
  },
  directives: {
    Ripple,
  },
  setup() {
    const APP_STORE_MODULE_NAME = 'app-products-categories'

    // Register module
    if (!store.hasModule(APP_STORE_MODULE_NAME)) store.registerModule(APP_STORE_MODULE_NAME, categoryStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(APP_STORE_MODULE_NAME)) store.unregisterModule(APP_STORE_MODULE_NAME)
    })

    const toast = useToast()

    const showNotification = ({ title, icon, variant }) => {
      toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          variant,
        },
      })
    }

    const isProductCategoryHandlerSidebarActive = ref(false)

    const blankCategory = {
      name: '',
      description: '',
      photo: null,
    }
    const category = ref(JSON.parse(JSON.stringify(blankCategory)))
    const clearCategoryData = () => {
      category.value = JSON.parse(JSON.stringify(blankCategory))
    }

    const {
      // fetchUsers,
      tableColumns,
      totalProductCategories,

      perPage,
      currentPage,

      perPageOptions,
      refProductCategoriesListTable,
      refetchData,
      fetchProductCategories,

    } = productCategoryHandler()

    const editCategory = item => {
      const { photo: image, ...rest } = item
      category.value = { ...rest, photo: null, showImage: image }
      isProductCategoryHandlerSidebarActive.value = true
    }
    const addCategory = item => {
      const formData = new FormData()
      formData.append('name', item.name)
      formData.append('description', item.description)
      formData.append('photo', item.photo)

      store.dispatch('app-products-categories/addCategory', { body: formData })
        .then(() => {
          refetchData()
          isProductCategoryHandlerSidebarActive.value = false
          showNotification({ title: 'Categoria creada con éxito', icon: 'CheckCircleIcon', variant: 'success' })
        })
        .catch(() => {
          showNotification({ title: 'Oops!, ocurrió un error', icon: 'AlertTriangleIcon', variant: 'danger' })
        })
    }

    const updateCategory = item => {
      const formData = new FormData()
      formData.append('name', item.name)
      formData.append('description', item.description)
      formData.append('photo', item.photo)

      store.dispatch('app-products-categories/updateCategory', { id: item.id, body: formData })
        .then(() => {
          refetchData()
          isProductCategoryHandlerSidebarActive.value = false
          showNotification({ title: 'Categoria actualizada con éxito', icon: 'CheckCircleIcon', variant: 'success' })
        })
        .catch(() => {
          showNotification({ title: 'Oops!, ocurrió un error', icon: 'AlertTriangleIcon', variant: 'danger' })
        })
    }

    const deleteCategory = item => {
      store.dispatch('app-products-categories/deleteCategory', { id: item.id })
        .then(() => {
          refetchData()
          showNotification({ title: 'Categoria eliminada con éxito', icon: 'CheckCircleIcon', variant: 'success' })
        })
        .catch(() => {
          showNotification({ title: 'Oops!, ocurrió un error', icon: 'AlertTriangleIcon', variant: 'danger' })
        })
    }

    return {
      isProductCategoryHandlerSidebarActive,
      category,

      tableColumns,

      perPage,
      currentPage,
      totalProductCategories,

      perPageOptions,
      refProductCategoriesListTable,
      refetchData,

      fetchProductCategories,

      clearCategoryData,
      editCategory,
      addCategory,
      updateCategory,
      deleteCategory,
    }
  },
}
</script>
