<template>
  <div>
    <b-sidebar
      id="sidebar-task-handler"
      sidebar-class="sidebar-lg"
      :visible="isCategoryHandlerSidebarActive"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @change="(val) => $emit('update:is-category-handler-sidebar-active', val)"
      @hidden="clearForm"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5
            class="mb-0"
          >
            {{ categoryLocal.id ? 'Editar categoria' : 'Agregar categoria' }}
          </h5>
          <div>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
            />
          </div>
        </div>

        <!-- Body -->
        <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
        >

          <!-- Form -->
          <b-form
            class="p-2"
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
          >

            <!-- Title -->
            <validation-provider
              #default="validationContext"
              name="Titulo"
              rules="required"
            >
              <b-form-group
                label="Titulo"
                label-for="title"
              >
                <b-form-input
                  id="title"
                  v-model="categoryLocal.name"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder=""
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Description -->
            <validation-provider
              #default="validationContext"
              name="Descripción"
              rules="required"
            >
              <b-form-group
                label="Descripción"
                label-for="description"
              >
                <b-form-textarea
                  id="description"
                  v-model="categoryLocal.description"
                  rows="3"
                  :state="getValidationState(validationContext)"
                  trim
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Image -->
            <validation-provider
              #default="validationContext"
              name="Imagen"
              :rules=" { required : !categoryLocal.id }"
            >
              <b-form-group
                label="Imagen"
                label-for="image"
              >
                <b-form-file
                  id="image"
                  ref="refInputElDesktop"
                  v-model="categoryLocal.photo"
                  :state="getValidationState(validationContext)"
                  placeholder="Elegir imagen (210x60px)"
                  accept="image/*"
                  @input="renderDesktop"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <div
              v-if="categoryLocal.showImage"
              class="my-1"
            >
              <b-img
                thumbnail
                fluid
                :src="categoryLocal.showImage"
                alt="Image Desktop"
              />
            </div>

            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
              >
                {{ categoryLocal.id ? 'Actualizar' : 'Agregar ' }}
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="outline-secondary"
                @click="hide"
              >
                Cancelar
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BButton, BFormInvalidFeedback, BFormFile, BImg, BFormTextarea,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email, url } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import { ref, toRefs } from '@vue/composition-api'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import productCategoryHandlerSidebar from '@/views/dramox/product/category/sidebar/productCategoryHandlerSidebar'

export default {
  components: {
    // BSV
    BButton,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BFormFile,
    BImg,
    BFormTextarea,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isCategoryHandlerSidebarActive',
    event: 'update:is-category-handler-sidebar-active',
  },
  props: {
    isCategoryHandlerSidebarActive: {
      type: Boolean,
      required: true,
    },
    category: {
      type: Object,
      required: true,
    },
    clearCategoryData: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      required,
      email,
      url,
    }
  },
  setup(props, { emit }) {
    const {
      categoryLocal,
      resetCategoryLocal,
      onSubmit,
    } = productCategoryHandlerSidebar(toRefs(props), emit)

    const refInputElDesktop = ref(null)
    // const showImageDesktop = ref(null)

    const { inputImageRenderer: renderDesktop } = useInputImageRenderer(refInputElDesktop, base64 => {
      // eslint-disable-next-line no-param-reassign

      // showImageDesktop.value = base64
      categoryLocal.value.showImage = base64
    })

    const {
      refFormObserver,
      getValidationState,
      resetForm,
      clearForm,
    } = formValidation(resetCategoryLocal, props.clearCategoryData)

    return {
      // Add New
      categoryLocal,
      onSubmit,

      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,

      renderDesktop,
      refInputElDesktop,
    }
  },
}
</script>
<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
</style>
